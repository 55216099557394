import React from "react";
import propTypes from "prop-types";
import { useSelector } from "react-redux";
import { UploadFileToAWS } from "../../../AWS/uploadFile";

function Item({
  label = null,
  section_id,
  prevData,
  setTemplateLink,
  link,
  setIsUploading,
}) {
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  function handleBrowse(event) {
    event.preventDefault();
    if (!label || label.toLowerCase() === "rank") {
      setTemplateLink(event.target?.files[0]?.name);
    } else if (label.toLowerCase() === "overall") {
      setTemplateLink({
        ...prevData,
        overAll: event.target?.files[0]?.name,
      });
    } else {
      let temp = JSON.parse(JSON.stringify(prevData));

      let sectionIndex = temp.sections.findIndex(
        (sect) =>
          sect.sectionId === section_id || sect.section_id === section_id
      );
      temp.sections[sectionIndex].url = event.target?.files[0]?.name;
      setTemplateLink({ ...temp });
    }

    const fileDate = new Date()
      .toLocaleString({ hour12: true })
      .toString()
      .split("/");
    if (event.target?.files[0]) {
      setIsUploading && setIsUploading(true);
      const val = UploadFileToAWS({
        key: "addTemplate",
        file: event.target?.files[0],
        path:
          "Asset/TestSettings/" +
          fileDate[2].toString().split(",")[0] +
          "/" +
          event.target?.files[0].name.toString().split(".")[0] +
          "_" +
          login_Details.userImsPin +
          "_" +
          fileDate[2].toString().split(/[, ]+/)[1] +
          "." +
          event.target?.files[0].name.toString().split(".")[1],
      });
      val.then((item) => {
        if (!label || label.toLowerCase() === "rank") {
          setTemplateLink(item);
        } else if (label.toLowerCase() === "overall") {
          setTemplateLink({
            ...prevData,
            overAll: item,
          });
        } else {
          let temp = prevData;
          let sectionIndex = temp.sections.findIndex((sect) => {
            return (
              sect.sectionId === section_id || sect.section_id === section_id
            );
          });
          temp.sections[sectionIndex].url = item;
          setTemplateLink({ ...temp });
        }
        setIsUploading && setIsUploading(false);
      });
    } else {
      alert("No files available!");
    }
  }

  function truncateString(str, maxLength = 20) {
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.slice(0, maxLength - 3) + "...";
    }
  }

  return (
    <div className="grid grid-cols-2 items-center justify-center">
      {label && label !== "rank" && (
        <label className="text-left w-[160px] whitespace-nowrap font-normal font-ibm text-base mr-5 ">
          {label}
        </label>
      )}
      <div className="border border-primary-600 rounded-xl flex items-center gap-4 pl-5 pr-16 py-[6px] ">
        <label className="font-ibm font-normal text-sm px-6 py-2 bg-primary-600 border border-black cursor-pointer  ">
          <input
            className="hidden "
            type="file"
            multiple
            onChange={handleBrowse}
            accept=".xlsx,.csv"
          />
          Choose File
        </label>
        <p className="font-normal font-ibm text-base">
          {link ? truncateString(link) : "No File Chosen"}
        </p>
      </div>
    </div>
  );
}

Item.propTypes = {
  label: propTypes.string,
  url: propTypes.string,
  section_id: propTypes.string,
  link: propTypes.string,
  prevData: propTypes.object,
  setTemplateLink: propTypes.func,
  setIsUploading: propTypes.func,
};
export default Item;
